/* Global CSS */

*:focus {
    outline: none;
}

body {
    margin: 0px;
    height: 100vh;
    
    background: linear-gradient(0deg, rgba(60, 60, 60, 1) 0%, rgba(20, 20, 20, 1) 100%);

    touch-action: pan-x pan-y;
    -webkit-tap-highlight-color: transparent;
}

@media screen and (min-width: 960px) {
    html {
        overflow-y: scroll;
        overflow-x: scroll;
    }
}
