.VideoStream {
    position: fixed;

    /*
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: scroll;
    text-align: center;
    */
}