.VideoControls {
    z-index: 10;
    
    position: fixed;

    width: max-content;

    bottom: 70px;
    left: 0;
    right: 0;
    margin: auto;

    bottom: 2vh;

}