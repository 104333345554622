.LoginForm {
    padding: 20px;
    max-width: 800px;
    margin: auto;   
    text-align: center;    
}

.LoginForm input {
    font-weight: bold;
    font-size: 1em;
    height: 40px;
    width: 100%;
}

.LoginForm input[type=password] {
    border: 3px solid #bbb;
    text-align: center;
}

.LoginForm input[type=checkbox] {
    width: 20px;
    display: initial;
    vertical-align: inherit;    
}

.LoginForm input[type=submit] {
    margin: auto;
    width: 80%;
    margin-top: 20px;
    color: #333;
    background: #fdfdfd;
    background: linear-gradient(to bottom, #fdfdfd 0%, #bebebe 100%);
    border: 3px solid #bbb;
    border-radius: 10px;
}

.Label {
    display: block;
    vertical-align: middle;
    font-family: sans-serif;    
    color: #fff;
}
