.Menu {
    position: fixed;

    width: 95vw;

    max-width: 800px;
    
    top: 60px;
    left: 0;
    right: 0;
    margin: auto;
}

